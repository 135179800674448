import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import SocialItem from './components/social';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-header">

          <img src={logo} className="App-logo" alt="logo" />



          <h3>
            سایت در حال طراحیست
        </h3>
          <p>
            از طریق شبکه های اجتماعی میتونید با من در تماس باشید
        </p>
<SocialItem/>
        

        </div>

      </div>
    );
  }
}

export default App;
