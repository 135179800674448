import React, { Component } from 'react';
import './social.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
    faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(
    fab,
    faEnvelope
)



class SocialItem extends Component {
    render() {
        return (
            <div className="social">
                <ul className="text-center">
                    <li className="linkedin-icon">
                        <a href="https://www.linkedin.com/in/hosseinroshan/">
                            <FontAwesomeIcon icon={['fab', 'linkedin']} size="2x" />
                        </a>
                    </li>
                    <li className="mail-icon">
                        <a href="mailto:hossein.roshan.70@gmail.com">
                            <FontAwesomeIcon icon={['fas', 'envelope']} size="2x" />
                        </a>
                    </li>
                    <li className="telegram-icon">
                        <a href="https://t.me/HosseinRoshan">
                            <FontAwesomeIcon icon={['fab', 'telegram']} size="2x" />
                        </a>
                    </li>
                    <li className="twitter-icon">
                        <a href="https://twitter.com/HosseinRoshan">
                            <FontAwesomeIcon icon={['fab', 'twitter']} size="2x" />
                        </a>
                    </li>
                    <li className="instagram-icon">
                        <a href="https://www.instagram.com/ho3einroshan/">
                            <FontAwesomeIcon icon={['fab', 'instagram']} size="2x" />
                        </a>
                    </li>
                    <li className="whatsapp-icon">
                        <a href="https://wa.me/989365322264">
                            <FontAwesomeIcon icon={['fab', 'whatsapp']} size="2x" />
                        </a>
                    </li>


                </ul>

            </div>
        );
    }
}

export default SocialItem;